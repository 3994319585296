<template>
  <section id="land-park-area">
    <v-container
      style="max-width: 1200px;"
    >
      <v-row
        justify="center"
        class="ma-0 pa-0"
      >
        <v-col
          v-for="(img, index) in images"
          :key="`img_${index}`"
          cols="12"
          class="pa-0"
        >
          <v-img
            :aspect-ratio="ratio"
            :src="img"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  import parkData from '@/data/data.park.json'

  export default {
    name: 'SessionParkArea',
    data () {
      return {
        points: {
          xs: 200,
          sm: 300,
          md: 500,
          lg: 650,
          xl: 800
        },
        ratio: 1.778,
        images: [],
      }
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}66`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || '800'
        return ht
      },
    },
    created () {
      const info = parkData.area || {}
      this.images = info.images || []
    }
  }
</script>

<style lang="sass">
</style>
